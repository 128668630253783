import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  p {
    margin: 0;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.black[200]};
    line-height: 1.6;
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.875rem;
  text-align: center;
  @media screen and (max-width: 330px) {
    font-size: 1.8rem;
  }
`;

export const RequestAccessMessage = styled.p`
  &&& {
    margin-top: 1.75rem;
    font-size: 1rem;
  }
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colorMappings.hyperlink};
  }
`;
