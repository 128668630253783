import * as React from 'react';
import { IconProps } from 'Atoms/Icons/types';

const CannotViewSvg: React.FC<IconProps> = ({ width = 360, height = 216 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 360 216"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M318.052 215.577L317.831 215.193C322.284 212.625 326.365 208.759 329.959 203.701C333.363 198.911 336.197 193.161 338.154 187.074C342.046 174.964 343.418 162.054 344.724 149.555C334.327 152.73 324.542 161.064 317.834 172.472C311.051 184.008 307.503 198.537 308.099 212.334L307.658 212.353C307.059 198.473 310.629 183.856 317.454 172.249C324.286 160.629 334.296 152.169 344.916 149.036L345.233 148.942L345.199 149.271C343.879 161.895 342.515 174.95 338.574 187.21C336.605 193.339 333.75 199.131 330.32 203.957C326.689 209.068 322.561 212.977 318.052 215.577Z"
      fill="#00AC5D"
    />
    <path
      d="M309.128 213.048L308.688 213.01C309.088 208.361 310.539 203.691 313.125 198.73C315.621 193.943 318.838 189.57 321.363 186.295C321.978 185.497 322.61 184.685 323.221 183.901C328.565 177.039 334.092 169.943 336.679 161.292L337.103 161.419C334.492 170.149 328.94 177.278 323.571 184.172C322.959 184.956 322.328 185.768 321.713 186.564C319.2 189.824 315.998 194.177 313.517 198.934C310.959 203.842 309.523 208.458 309.128 213.048Z"
      fill="#00AC5D"
    />
    <path
      d="M320.817 190.214C320.206 190.214 319.598 190.174 318.998 190.094L319.057 189.655C321.7 190.011 324.492 189.552 327.132 188.33C329.777 187.105 332.138 185.175 333.961 182.746L334.314 183.011C332.449 185.498 330.028 187.476 327.318 188.73C325.199 189.711 322.983 190.214 320.817 190.214Z"
      fill="#00AC5D"
    />
    <path
      d="M325.171 181.502C323.724 176.411 324.716 170.294 327.699 165.915L328.063 166.164C325.151 170.439 324.182 176.411 325.594 181.38L325.171 181.502Z"
      fill="#00AC5D"
    />
    <path
      d="M333.158 215.631L333.002 215.218C338.012 213.325 342.526 209.457 345.714 204.326C348.418 199.971 350.249 194.656 351.157 188.524C345.601 188.774 340.044 191.012 335.874 194.689L335.582 194.358C339.893 190.556 345.662 188.265 351.407 188.073L351.671 188.063L351.633 188.324C350.73 194.628 348.864 200.091 346.089 204.559C342.851 209.773 338.259 213.704 333.158 215.631Z"
      fill="#00AC5D"
    />
    <path
      d="M319.722 214.46L319.534 214.06C329.524 209.364 338.767 202.599 346.265 194.498L346.589 194.798C339.054 202.942 329.764 209.741 319.722 214.46Z"
      fill="#00AC5D"
    />
    <path
      d="M281.132 215.902C277.259 214.448 273.729 211.733 270.926 208.051C268.346 204.662 266.42 200.544 265.357 196.141C263.253 187.429 264.249 178.136 265.348 170.11L265.397 169.751L265.693 169.959C272.75 174.904 280.047 180.017 285.339 187.071C291.233 194.926 293.327 204.161 290.939 211.774L290.518 211.642C292.862 204.164 290.794 195.078 284.985 187.336C279.809 180.437 272.672 175.39 265.736 170.529C264.663 178.431 263.732 187.529 265.786 196.037C266.834 200.381 268.734 204.442 271.277 207.783C274.03 211.398 277.491 214.062 281.287 215.487L281.132 215.902Z"
      fill="#00AC5D"
    />
    <path
      d="M270.183 178.124L269.786 178.32L286.831 212.828L287.227 212.633L270.183 178.124Z"
      fill="#00AC5D"
    />
    <path
      d="M279.051 197.97C276.797 197.97 274.529 197.372 272.473 196.234C270.327 195.047 268.558 193.351 267.355 191.332L267.736 191.106C268.897 193.057 270.609 194.697 272.687 195.847C274.764 196.996 277.064 197.575 279.333 197.524L279.344 197.966C279.245 197.969 279.148 197.97 279.051 197.97Z"
      fill="#00AC5D"
    />
    <path
      d="M266.814 132.585H230.698V134.164H266.814V132.585Z"
      fill="#ABB2B2"
    />
    <path
      d="M261.796 136.508H230.698V138.087H261.796V136.508Z"
      fill="#ABB2B2"
    />
    <path
      d="M250.314 140.431H230.698V142.009H250.314V140.431Z"
      fill="#ABB2B2"
    />
    <path d="M262.464 147.731H230.698V149.31H262.464V147.731Z" fill="#CBD2CF" />
    <path
      d="M258.052 151.654H230.698V153.232H258.052V151.654Z"
      fill="#CBD2CF"
    />
    <path
      d="M152.634 128.718H137.305V152.748H152.634V128.718Z"
      fill="#9DE1BC"
    />
    <path
      d="M173.757 128.678H156.525V136.615H173.757V128.678Z"
      fill="#51C696"
    />
    <path
      d="M173.757 140.483H156.525V152.779H173.757V140.483Z"
      fill="#9DE1BC"
    />
    <path
      d="M217.958 152.952H186.051C184.692 152.952 183.586 151.846 183.586 150.487V134.459C183.586 133.099 184.692 131.994 186.051 131.994H217.958C219.317 131.994 220.423 133.099 220.423 134.459V150.487C220.423 151.846 219.317 152.952 217.958 152.952ZM186.051 132.38C184.904 132.38 183.971 133.313 183.971 134.46V150.487C183.971 151.633 184.903 152.567 186.051 152.567H217.958C219.104 152.567 220.038 151.633 220.038 150.487V134.459C220.038 133.312 219.104 132.379 217.958 132.379H186.051V132.38Z"
      fill="#51C696"
    />
    <path
      d="M187.421 148.509L187.075 148.335L189.255 143.976L191.949 146.797L195.157 139.869L199.459 143.91L204.191 139.177L208.817 142.004L214.753 135.815L215.031 136.082L208.88 142.495L204.249 139.664L199.467 144.448L195.282 140.516L192.061 147.474L189.355 144.639L187.421 148.509Z"
      fill="#00AC5D"
    />
    <path
      d="M207.306 147.618H203.642V149.547H207.306V147.618Z"
      fill="#51C696"
    />
    <path d="M216.081 148.39H209.717V148.776H216.081V148.39Z" fill="#00AC5D" />
    <path
      d="M216.389 106.211C215.095 101.389 211.443 97.536 206.743 95.9595L202.005 110.082L216.389 106.211Z"
      fill="#9DE1BC"
    />
    <path
      d="M202.005 110.081L195.412 123.439C197.4 124.423 199.637 124.978 202.005 124.978C210.231 124.978 216.9 118.308 216.9 110.081C216.9 108.742 216.721 107.445 216.39 106.211L202.005 110.081Z"
      fill="#51C696"
    />
    <path
      d="M206.743 95.96C205.254 95.4607 203.662 95.1855 202.005 95.1855C193.779 95.1855 187.109 101.855 187.109 110.082C187.109 115.941 190.493 121.008 195.411 123.44L202.004 110.082L206.743 95.96Z"
      fill="#00AC5D"
    />
    <path
      d="M166.022 120.542H145.041V122.121H166.022V120.542Z"
      fill="#9DE1BC"
    />
    <g opacity="0.4">
      <path
        d="M173.95 117.087H137.112V95.7881H173.95V117.087ZM137.497 116.7H173.564V96.1743H137.497V116.7Z"
        fill="#47474F"
      />
      <path
        d="M171.251 100.438C170.028 100.192 168.817 100.989 168.044 101.971C167.677 102.435 167.375 102.946 167.078 103.46C166.748 104.03 166.423 104.604 166.024 105.127C165.266 106.12 164.089 106.951 162.857 106.763C160.858 106.459 159.765 103.714 157.743 103.75C156.799 103.766 155.989 104.419 155.346 105.113C154.702 105.807 154.116 106.595 153.279 107.033C152.108 107.646 150.663 107.447 149.45 106.926C148.236 106.405 147.172 105.593 146.018 104.949C144.085 103.87 141.889 103.301 139.679 103.242V113.661H171.384V104.077V100.474C171.339 100.46 171.297 100.447 171.251 100.438Z"
        fill="#47474F"
      />
      <path
        d="M149.449 106.926C150.662 107.448 152.107 107.646 153.278 107.033C154.116 106.595 154.703 105.807 155.346 105.113C155.988 104.419 156.798 103.766 157.742 103.75C159.764 103.714 160.857 106.458 162.856 106.763C164.089 106.951 165.265 106.12 166.023 105.127C166.422 104.604 166.747 104.029 167.077 103.459C165.848 103.515 164.62 103.577 163.556 103.009C162.498 102.444 161.804 101.343 160.748 100.772C158.854 99.7468 156.537 100.725 154.65 101.763C153.522 102.383 152.263 103.049 151.014 102.743C149.601 102.396 148.772 100.956 147.595 100.098C145.341 98.4546 141.957 99.2526 140.03 101.272C140.029 101.273 140.028 101.275 140.026 101.276C139.796 101.519 139.677 101.83 139.677 102.147V103.241C141.886 103.299 144.083 103.869 146.016 104.948C147.17 105.592 148.235 106.404 149.449 106.926Z"
        fill="#979797"
      />
    </g>
    <path
      d="M266.997 125.615H230.103V95.7593H266.997V125.615ZM230.544 125.174H266.553V96.2016H230.544V125.174Z"
      fill="#51C696"
    />
    <path
      d="M240.064 108.197H236.206V121.185H240.064V108.197Z"
      fill="#51C696"
    />
    <path d="M240.064 101.64H236.206V108.197H240.064V101.64Z" fill="#9DE1BC" />
    <path
      d="M247.006 101.639H243.148V111.379H247.006V101.639Z"
      fill="#9DE1BC"
    />
    <path
      d="M247.006 111.379H243.148V121.185H247.006V111.379Z"
      fill="#51C696"
    />
    <path d="M253.95 105.931H250.092V121.185H253.95V105.931Z" fill="#51C696" />
    <path d="M253.95 101.639H250.092V105.93H253.95V101.639Z" fill="#9DE1BC" />
    <path
      d="M260.893 111.621H257.035V121.185H260.893V111.621Z"
      fill="#51C696"
    />
    <path
      d="M260.893 101.639H257.035V111.621H260.893V101.639Z"
      fill="#9DE1BC"
    />
    <path
      d="M116.521 215.384L109.302 207.869L109.317 202.324L100.547 202.585L101.271 215.384H116.521Z"
      fill="#102048"
    />
    <path
      d="M109.375 181.49L99.4287 182.817L99.821 189.756L109.353 189.525L109.375 181.49Z"
      fill="#E28B6D"
    />
    <path
      d="M109.353 189.525L99.8213 189.756L100.547 202.585L109.317 202.324L109.353 189.525Z"
      fill="#F9B384"
    />
    <path
      d="M109.112 207.467L105.354 209.235L105.731 210.035L109.488 208.267L109.112 207.467Z"
      fill="white"
    />
    <path
      d="M130.373 179.206L120.795 178.837L119.537 186.168L128.712 187.508L130.373 179.206Z"
      fill="#E28B6D"
    />
    <path
      d="M132.508 207.943L126.614 197.996L127.377 194.185C124.777 193.59 120.765 192.672 118.506 192.169L116.521 203.744L132.508 207.943Z"
      fill="#102048"
    />
    <path
      d="M128.712 187.508L119.537 186.167L118.508 192.169C120.766 192.672 124.779 193.589 127.378 194.185L128.712 187.508Z"
      fill="#F9B384"
    />
    <path
      d="M126.475 197.57L122.754 198.823L123.036 199.661L126.757 198.408L126.475 197.57Z"
      fill="white"
    />
    <path
      d="M136.782 180.313C136.782 180.313 132.804 185.322 125.435 183.699C118.07 182.081 119.396 186.352 115.267 187.237C111.143 188.121 99.6496 180.901 95.8172 187.679C95.8172 187.679 93.5357 162.016 94.5751 145.773C94.8583 141.387 95.3801 137.686 96.2594 135.366L113.893 135.122L117.774 135.069C117.774 135.069 117.787 135.153 117.818 135.321C117.884 135.671 118.017 136.382 118.238 137.399C119.68 144.087 124.736 163.924 136.782 180.313Z"
      fill="#196445"
    />
    <path
      d="M118.238 137.399C114.294 140.003 106.072 144.478 94.5742 145.773C94.8575 141.387 95.3792 137.687 96.2586 135.366L113.892 135.123L117.817 135.321C117.884 135.67 118.017 136.382 118.238 137.399Z"
      fill="#124C34"
    />
    <path
      d="M98.7364 159.346L102.985 144.928L83.6924 139.242L79.4435 153.66L98.7364 159.346Z"
      fill="white"
    />
    <path
      d="M98.8842 159.618L79.167 153.806L83.5415 138.964L103.259 144.776L98.8842 159.618ZM79.7162 153.508L98.5846 159.07L102.71 145.076L83.8421 139.514L79.7162 153.508Z"
      fill="#86A7FF"
    />
    <path
      d="M99.2017 146.029L97.9365 150.322L98.3607 150.447L99.6259 146.154L99.2017 146.029Z"
      fill="#86A7FF"
    />
    <path
      d="M96.5518 145.247L93.5059 155.583L93.9301 155.708L96.976 145.372L96.5518 145.247Z"
      fill="#86A7FF"
    />
    <path
      d="M93.9015 144.469L90.8555 154.805L91.2797 154.93L94.3257 144.594L93.9015 144.469Z"
      fill="#86A7FF"
    />
    <path
      d="M91.2511 143.685L88.2051 154.021L88.6293 154.147L91.6753 143.811L91.2511 143.685Z"
      fill="#86A7FF"
    />
    <path
      d="M88.602 142.905L86.2744 150.803L86.6986 150.928L89.0262 143.03L88.602 142.905Z"
      fill="#86A7FF"
    />
    <path
      d="M128.927 108.986C128.927 108.986 129.472 103.733 129.962 103.822C130.452 103.91 130.398 105.193 130.398 105.193C130.398 105.193 131.545 102.264 132.077 102.267C132.61 102.27 132.066 103.464 132.066 103.464C132.066 103.464 132.658 102.267 133.101 102.358C133.543 102.45 133.18 103.467 133.18 103.467C133.18 103.467 133.643 102.485 134.09 102.659C134.538 102.833 134.256 103.637 134.256 103.637C134.256 103.637 134.501 103 134.918 103.425C135.336 103.849 133.193 110 133.193 110L130.173 110.862L128.927 108.986Z"
      fill="#F9B384"
    />
    <path
      d="M133.207 110.019C133.207 110.019 130.572 120.855 123.929 124.021C122.373 124.767 120.596 125.091 118.584 124.754L117.395 122.637L109.597 108.764L112.319 107.809C112.319 107.809 118.437 110.091 121.753 115.175C121.753 115.175 124.848 114.883 128.971 108.694L133.207 110.019Z"
      fill="#713784"
    />
    <path
      d="M123.928 124.021C122.372 124.768 120.595 125.091 118.584 124.754L117.395 122.637L117.699 117.46C117.699 117.46 122.208 120.02 123.928 124.021Z"
      fill="#102048"
    />
    <path
      d="M96.4818 144.926C96.4818 144.926 95.8857 150.027 95.364 149.941C94.8423 149.854 94.9055 148.57 94.9055 148.57C94.9055 148.57 93.6715 151.506 93.1039 151.505C92.5363 151.504 93.1212 150.307 93.1212 150.307C93.1212 150.307 92.4854 151.508 92.0146 151.417C91.5438 151.327 91.9351 150.309 91.9351 150.309C91.9351 150.309 91.4379 151.293 90.962 151.121C90.4861 150.949 90.7888 150.143 90.7888 150.143C90.7888 150.143 90.5249 150.781 90.0826 150.358C89.6404 149.935 91.9504 143.772 91.9504 143.772L95.1704 142.896L96.4818 144.926Z"
      fill="#F9B384"
    />
    <path
      d="M107.053 107.918C93.976 109.503 90.1263 127.628 91.6181 144.65L96.4806 144.944C96.4806 144.944 97.2907 128.292 103.996 120.334L109.595 108.765L107.053 107.918Z"
      fill="#713784"
    />
    <path
      d="M109.596 108.765L118.658 114.881C118.658 114.881 121.162 126.596 121.016 136.985C121.016 136.985 103.481 143.47 94.7871 136.691L101.639 114.438L109.596 108.765Z"
      fill="#713784"
    />
    <path
      d="M114.797 98.1737C115.637 95.2449 112.69 91.8188 107.054 94.7293C107.054 94.7293 101.455 95.6872 102.449 105.413L108.343 105.634L114.797 98.1737Z"
      fill="#102048"
    />
    <path
      d="M108.232 94.5267C109.002 91.4318 105.641 90.9365 105.08 92.835C105.006 93.0878 103.367 92.1849 102.909 93.6605C102.383 95.3521 104.209 96.4782 106.27 96.0543L108.232 94.5267Z"
      fill="#102048"
    />
    <path
      d="M112.321 101.987V107.81L109.778 111.381L107.056 107.92L107.201 101.876L112.321 101.987Z"
      fill="#F9B384"
    />
    <path
      d="M112.32 103.977V107.712C110.499 106.921 109.717 104.529 109.717 104.529L112.32 103.977Z"
      fill="#102048"
    />
    <path
      d="M114.798 98.1739C114.798 98.1739 116.609 107.734 109.718 104.529L107.455 101.931L108.863 96.6809L112.484 95.7964L114.798 98.1739Z"
      fill="#F9B384"
    />
    <path
      d="M114.798 98.1736C114.798 98.1736 113.354 95.9265 111.548 97.0128C109.743 98.0992 109.835 101.913 107.956 100.955C106.077 99.9967 107.164 97.0495 107.164 97.0495L109.927 94.9492L113.022 95.2988L114.798 98.1736Z"
      fill="#102048"
    />
    <path
      d="M108.222 102.596C108.847 102.235 109.02 101.364 108.608 100.65C108.196 99.9365 107.355 99.6507 106.73 100.012C106.105 100.373 105.932 101.244 106.344 101.958C106.755 102.671 107.596 102.957 108.222 102.596Z"
      fill="#F9B384"
    />
    <path
      d="M109.817 115.398C110.306 115.398 110.702 115.001 110.702 114.513C110.702 114.024 110.306 113.628 109.817 113.628C109.329 113.628 108.933 114.024 108.933 114.513C108.933 115.001 109.329 115.398 109.817 115.398Z"
      fill="#102048"
    />
    <path
      d="M97.2144 138.171L96.7803 138.086C96.7864 138.053 97.4304 134.773 98.5115 131.102C99.9707 126.152 101.491 122.885 103.032 121.391L103.339 121.708C99.7842 125.157 97.2398 138.041 97.2144 138.171Z"
      fill="#102048"
    />
    <path
      d="M95.5754 127.732C94.7632 127.122 92.8618 127.185 92.8425 127.186L92.8262 126.745C92.9107 126.742 94.9049 126.677 95.8403 127.379L95.5754 127.732Z"
      fill="#102048"
    />
    <path
      d="M298.31 201.901L298.162 210.301L296.246 215.385H306.635L306.046 201.68L298.31 201.901Z"
      fill="#102048"
    />
    <path
      d="M283.022 202.785V209.748L274.844 215.385H291.31L290.316 202.011L283.022 202.785Z"
      fill="#102048"
    />
    <path
      d="M283.292 209.398L282.756 210.102L285.224 211.98L285.76 211.277L283.292 209.398Z"
      fill="white"
    />
    <path
      d="M296.834 134.48L296.79 134.649L295.323 140.436L292.122 153.049C291.676 155.061 291.34 157.231 291.092 159.504C289.532 173.858 291.548 192.206 292.595 200.119C292.869 202.197 291.251 204.036 289.156 204.036H283.334C281.995 204.036 280.774 203.263 280.195 202.055C272.773 186.511 273.98 163.297 275.469 157.615C277.825 148.627 279.447 135.218 279.447 135.218L284.394 135.006L296.834 134.48Z"
      fill="#196445"
    />
    <path
      d="M307.979 199.978C307.93 201.977 306.299 203.572 304.301 203.572H299.314C297.471 203.572 295.911 202.206 295.672 200.376C294.721 193.125 292.405 174.858 291.092 159.504C290.408 151.449 290 144.198 290.213 139.759C290.288 138.075 290.456 136.797 290.735 136.032L296.79 134.649L302.957 133.242C302.957 133.242 304.487 134.874 305.893 143.56C307.355 152.625 308.677 169.375 307.979 199.978Z"
      fill="#196445"
    />
    <path
      d="M290.464 171.775C290.191 166.113 290.364 161.286 290.996 157.018C291.199 155.644 291.923 154.401 293.035 153.517L297.178 150.224L297.453 150.57L293.31 153.864C292.286 154.677 291.619 155.82 291.433 157.083C290.806 161.322 290.633 166.122 290.905 171.755L290.464 171.775Z"
      fill="#124C34"
    />
    <path
      d="M278.039 173.605L277.985 173.167C280.761 172.822 283.14 172.773 285.258 173.019L285.207 173.458C283.124 173.216 280.779 173.265 278.039 173.605Z"
      fill="#124C34"
    />
    <path
      d="M297.932 172.574L297.878 172.135C300.654 171.791 303.033 171.742 305.15 171.988L305.099 172.427C303.017 172.185 300.672 172.233 297.932 172.574Z"
      fill="#124C34"
    />
    <path
      d="M285.725 198.784C281.945 198.784 279.143 198.11 279.101 198.1L279.207 197.671C279.271 197.686 285.705 199.231 292.208 197.584L292.317 198.012C290.021 198.593 287.734 198.784 285.725 198.784Z"
      fill="#124C34"
    />
    <path
      d="M301.3 198.81C300.703 198.81 300.137 198.792 299.622 198.765C297.178 198.636 295.316 198.278 295.298 198.274L295.382 197.84C295.455 197.854 302.728 199.239 307.569 197.448L307.722 197.863C305.717 198.605 303.326 198.81 301.3 198.81Z"
      fill="#124C34"
    />
    <path
      d="M305.891 143.561C303.076 142.058 299.19 141.077 295.322 140.436C293.584 140.144 291.847 139.927 290.211 139.759C285.035 139.233 280.884 139.233 280.884 139.233L284.394 135.007L296.834 134.481L296.79 134.649L302.957 133.243C302.957 133.243 304.486 134.875 305.891 143.561Z"
      fill="#124D34"
    />
    <path d="M289.204 101.208H284.09V109.745H289.204V101.208Z" fill="#E28B6D" />
    <path
      d="M286.485 104.758C286.485 104.758 285.968 106.618 284.09 107.892V104.166L286.485 104.758Z"
      fill="#102048"
    />
    <path
      d="M281.453 96.8521L281.093 100.445C281.093 100.445 279.42 102.691 280.464 102.691C281.587 102.691 281.408 103.141 281.408 103.141C281.408 103.141 280.779 107.812 286.483 104.758L287.696 97.7509L281.453 96.8521Z"
      fill="#E28B6D"
    />
    <path
      d="M281.453 96.852C281.453 96.852 283.003 96.6502 283.406 98.2888C283.811 99.9285 284.35 100.938 285.72 99.794L287.225 97.3238L284.642 96.2456L281.453 96.852Z"
      fill="#102048"
    />
    <path
      d="M281.453 96.852C281.453 95.6842 282.838 92.899 287.202 93.6409C291.373 94.3492 292.03 100.199 289.875 102.872C289.875 102.872 288.774 104.601 287.293 101.435L284.756 98.0209L284.217 96.9427L281.453 96.852Z"
      fill="#102048"
    />
    <path
      d="M287.521 101.22C287.108 101.934 286.27 102.221 285.646 101.862C285.024 101.502 284.852 100.632 285.265 99.9172C285.678 99.2028 286.516 98.9155 287.14 99.2752C287.763 99.6349 287.934 100.505 287.521 101.22Z"
      fill="#E28B6D"
    />
    <path
      d="M297.204 107.881C307.002 111.05 314.738 124.313 305.677 124.534C296.615 124.755 291.089 116.355 291.089 116.355L297.204 107.881Z"
      fill="#D2D2D2"
    />
    <path
      d="M253.845 117.997C253.845 117.997 249.004 115.888 248.84 116.358C248.675 116.829 249.819 117.413 249.819 117.413C249.819 117.413 246.705 116.969 246.445 117.435C246.186 117.901 247.494 118.015 247.494 118.015C247.494 118.015 246.16 117.941 246.022 118.371C245.885 118.802 246.948 118.986 246.948 118.986C246.948 118.986 245.866 118.905 245.797 119.381C245.729 119.857 246.567 120.008 246.567 120.008C246.567 120.008 245.893 119.908 246.057 120.48C246.221 121.052 252.629 122.213 252.629 122.213L254.865 120.008L253.845 117.997Z"
      fill="#E28B6D"
    />
    <path
      d="M302.236 137.46L280.358 139.848C278.515 140.046 276.919 138.575 276.976 136.718L277.25 127.69L277.379 123.53L277.383 123.428C277.383 123.428 277.224 123.619 276.905 123.914C276.905 123.918 276.901 123.918 276.896 123.924C276.688 124.114 276.414 124.347 276.074 124.604C273.169 126.797 265.424 130.564 252.627 122.213L253.843 118L267.657 118.565C267.657 118.565 273.85 113.561 279.35 110.125C282.943 107.879 287.103 106.637 291.338 106.748C293.251 106.797 295.308 107.102 297.204 107.88C301.868 109.807 304.18 127.014 304.94 134.101C305.123 135.776 303.912 137.28 302.236 137.46Z"
      fill="white"
    />
    <path
      d="M266.134 122.631C265.733 119.886 267.447 118.453 267.521 118.394L267.801 118.736C267.797 118.74 267.395 119.075 267.046 119.713C266.579 120.564 266.42 121.525 266.572 122.568L266.134 122.631Z"
      fill="#D2D2D2"
    />
    <path
      d="M279.338 121.88C279.338 121.88 279.201 125.444 277.251 127.689L277.379 123.529C278.109 122.937 278.945 122.243 279.338 121.88Z"
      fill="#D2D2D2"
    />
    <path
      d="M272.861 126.629L272.774 126.196C275.974 125.556 280.282 120.195 280.326 120.141L280.671 120.417C280.66 120.431 279.554 121.813 278.055 123.257C276.026 125.211 274.278 126.346 272.861 126.629Z"
      fill="#D2D2D2"
    />
    <path
      d="M300.883 53.2783C301.64 51.9546 303.327 51.4959 304.651 52.2536C305.974 53.0113 306.433 54.6978 305.675 56.0216C304.918 57.3454 303.231 57.804 301.907 57.0463C300.584 56.2886 300.125 54.6021 300.883 53.2783Z"
      fill="#01A964"
    />
    <path
      d="M340.208 57.7591C339.546 57.3804 338.702 57.6098 338.324 58.2715C337.945 58.9332 338.174 59.7767 338.836 60.1555C339.498 60.5343 340.341 60.3049 340.72 59.6431C341.099 58.9814 340.869 58.1379 340.208 57.7591Z"
      fill="#01A964"
    />
    <path
      d="M316.544 56.5888L317.065 53.5671C317.088 53.4386 317.059 53.3089 316.986 53.2097L315.286 50.907C315.046 50.5819 315.332 50.082 315.734 50.1242L318.581 50.4246C318.703 50.4369 318.828 50.3972 318.929 50.3115L321.27 48.3314C321.601 48.0524 322.055 48.3123 321.982 48.739L321.461 51.7606C321.438 51.8892 321.467 52.0189 321.539 52.1181L323.24 54.4208C323.48 54.7459 323.194 55.2458 322.792 55.2035L319.945 54.9032C319.823 54.8908 319.697 54.9306 319.597 55.0163L317.256 56.9964C316.925 57.2754 316.471 57.0154 316.544 56.5888Z"
      fill="#9DE1BC"
    />
    <path
      d="M339.137 78.9367L339.905 74.849C339.939 74.6744 339.905 74.5008 339.811 74.3698L337.637 71.3132C337.33 70.8816 337.721 70.199 338.249 70.2453L341.985 70.5714C342.144 70.5854 342.311 70.5267 342.446 70.4103L345.581 67.6772C346.024 67.2911 346.615 67.6294 346.507 68.2071L345.738 72.2948C345.705 72.4694 345.739 72.643 345.833 72.774L348.007 75.8306C348.314 76.2622 347.923 76.9448 347.395 76.8985L343.658 76.5717C343.498 76.5576 343.331 76.6163 343.196 76.7327L340.061 79.4658C339.62 79.8527 339.029 79.5144 339.137 78.9367Z"
      fill="#9DE1BC"
    />
    <path
      d="M15.4374 76.2935C15.4101 75.5786 15.9672 74.9772 16.6821 74.9499C17.3971 74.9226 17.9984 75.4797 18.0258 76.1946C18.0531 76.9095 17.4959 77.5109 16.781 77.5382C16.0661 77.5655 15.4647 77.0084 15.4374 76.2935Z"
      fill="#01A964"
    />
    <path
      opacity="0.2"
      d="M39.183 92.3364C39.1248 90.8123 40.3125 89.5301 41.8367 89.4719C43.3609 89.4137 44.643 90.6015 44.7012 92.1256C44.7595 93.6498 43.5717 94.9319 42.0475 94.9902C40.5233 95.0484 39.2412 93.8606 39.183 92.3364Z"
      fill="#01A964"
    />
    <path
      d="M80.5463 58.441C79.7844 58.4701 79.1903 59.1114 79.2194 59.8733C79.2485 60.6352 79.8898 61.2293 80.6517 61.2002C81.4136 61.1711 82.0077 60.5298 81.9785 59.7679C81.9494 59.006 81.3082 58.4119 80.5463 58.441Z"
      fill="#01A964"
    />
    <path
      d="M146.167 74.7504L144.655 70.8757C144.591 70.71 144.47 70.5807 144.321 70.5194L140.859 69.0774C140.369 68.874 140.339 68.088 140.812 67.8478L144.154 66.1459C144.296 66.0735 144.407 65.9354 144.46 65.7651L145.672 61.7864C145.843 61.2246 146.523 61.1986 146.738 61.7457L148.251 65.6203C148.315 65.7861 148.435 65.9154 148.585 65.9766L152.047 67.4187C152.536 67.6221 152.566 68.4081 152.094 68.6482L148.75 70.3502C148.607 70.4226 148.497 70.5607 148.444 70.731L147.232 74.7097C147.063 75.2714 146.382 75.2974 146.167 74.7504Z"
      fill="#9DE1BC"
    />
    <path
      d="M95.3187 118.425C95.3798 118.716 95.193 119.002 94.9014 119.063C94.6098 119.124 94.3241 118.938 94.263 118.646C94.2019 118.354 94.3886 118.069 94.6802 118.008C94.9718 117.946 95.2576 118.133 95.3187 118.425Z"
      fill="#D8D8D8"
    />
    <path
      opacity="0.2"
      d="M77.4124 111.848C77.6635 113.046 76.8961 114.22 75.698 114.471C74.4999 114.722 73.3257 113.955 73.0746 112.757C72.8236 111.559 73.5909 110.384 74.789 110.133C75.9871 109.882 77.1613 110.65 77.4124 111.848Z"
      fill="#D8D8D8"
    />
    <path
      d="M66.2038 130.116C67.0049 129.948 67.5181 129.163 67.3503 128.362C67.1824 127.561 66.3969 127.048 65.5959 127.216C64.7949 127.383 64.2816 128.169 64.4495 128.97C64.6173 129.771 65.4028 130.284 66.2038 130.116Z"
      fill="#D8D8D8"
    />
    <path
      d="M214.6 58.0392L215.384 59.3027C215.418 59.3565 215.47 59.3943 215.528 59.4069L216.886 59.6939C217.078 59.7344 217.135 60.0078 216.976 60.122L215.847 60.9302C215.799 60.9651 215.766 61.0199 215.757 61.0833L215.546 62.5554C215.516 62.7632 215.268 62.8152 215.157 62.637L214.373 61.3735C214.339 61.3197 214.287 61.2819 214.229 61.2693L212.871 60.9824C212.679 60.9418 212.621 60.6684 212.781 60.5543L213.91 59.746C213.958 59.7111 213.991 59.6563 213.999 59.5929L214.21 58.1208C214.241 57.9131 214.489 57.861 214.6 58.0392Z"
      fill="#7B7979"
    />
    <path
      d="M32.7075 127.82L33.7456 129.549C33.7898 129.624 33.8579 129.676 33.9343 129.693L35.7069 130.101C35.9573 130.158 36.0356 130.532 35.8293 130.685L34.3697 131.77C34.3074 131.816 34.2658 131.891 34.2544 131.977L33.9981 133.978C33.9621 134.26 33.6389 134.328 33.4918 134.084L32.4537 132.354C32.4095 132.28 32.3413 132.228 32.265 132.211L30.4924 131.803C30.242 131.746 30.1637 131.372 30.37 131.219L31.8303 130.134C31.8926 130.087 31.9342 130.012 31.9456 129.927L32.2019 127.926C32.2372 127.643 32.5604 127.576 32.7075 127.82Z"
      fill="#7B7979"
    />
  </svg>
);

export { CannotViewSvg };
