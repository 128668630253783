import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  text-align: left;
  justify-content: center;
  width: 100%;
`;

export const Listener = styled.div`
  display: flex;
  flex-direction: column;
  width: 11.0625rem;
  margin: 0 0.5rem 0.75rem;
  background-color: #e5e5e5;
  padding: 0.5rem 1.5625rem;
  @media screen and (max-width: 600px) {
    padding: 0.5rem 0.5625rem;
    width: 9.0625rem;
  }
`;

export const Name = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  width: 100%;
`;

export const Text = styled.div`
  font-size: 1rem;
  line-height: 1.1rem;
`;
