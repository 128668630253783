import * as React from 'react';
import styled from 'styled-components';
import { Button as ButtonBase } from 'Client/components/molecules';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  background: ${({ theme }) => theme.colorMappings.lightGreenBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  svg {
    @media (max-width: 359px) {
      max-width: 20rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.375rem;
  text-align: center;
  margin: 3.875rem 0 0;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black[200]};
  margin: 1.4375rem 2.9375rem 1.8125rem;
`;

export const Button = styled(({ inverse, ...props }) => (
  <ButtonBase {...props} />
))`
  background: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
  color: white;
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
    border: none;
  }
`;
