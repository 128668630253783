import * as React from 'react';
import { CannotView } from './components/CannotView';
import { PublicAfterContributionProps } from '../types';

const PublicAfterContribution: React.FC<PublicAfterContributionProps> = ({
  canView,
  contributionsNumber,
  slug,
}: PublicAfterContributionProps) => {
  return (
    <>
      {!canView && (
        <CannotView contributionsNumber={contributionsNumber} slug={slug} />
      )}
    </>
  );
};

export { PublicAfterContribution };
