import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { HeaderProps } from './types';
import { Wrapper, Title, RequestAccessMessage } from './Header.styles';

export const Header: React.FC<HeaderProps> = ({
  customerName,
  title,
  subtitle,
  requestAccess,
}: HeaderProps) => {
  const { name } = useProject();
  const { t } = useTranslation();
  const mailLink = `mailto:support@commonplace.is?subject=Invitation request for ${name}&body=${name} is giving access to this project on an invitation only basis. Please provide information about you and why you should be considered for participation below:`;
  return (
    <Wrapper>
      <Title>
        {title || t('Access {{ projectName }}', { projectName: name })}
      </Title>
      {subtitle ? (
        <p>{subtitle}</p>
      ) : (
        <p>
          {t('Hello!')}
          <br />
          {t(
            '{{ customerName }} is giving you access to this project on an invitation only basis.',
            { customerName }
          )}
        </p>
      )}

      {!!requestAccess && (
        <RequestAccessMessage>
          <a href={mailLink}>{requestAccess}</a>
        </RequestAccessMessage>
      )}
    </Wrapper>
  );
};
