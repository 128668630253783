import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useAnalytics, MixpanelEventTypes } from 'Client/utils/hooks';
import {
  Wrapper,
  ImageWrapper,
  Content,
  Title,
  Text,
  Button,
} from './CannotView.styles';
import { CannotViewProps } from '../../../types';
import { CannotViewSvg } from './CannotViewSvg';

const CannotView: React.FC<CannotViewProps> = ({
  slug,
  contributionsNumber,
}: CannotViewProps) => {
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.preventDefault();
    trackEvent(MixpanelEventTypes.CLICKED_LINK, { path: `/proposals/${slug}` });
    router.push(`/proposals/${slug}`);
  };

  return (
    <Wrapper>
      <ImageWrapper>
        <CannotViewSvg />
      </ImageWrapper>
      <Content>
        <Title>
          {t(`{{contributionsNumber}} responses`, {
            contributionsNumber: String(contributionsNumber) || '0',
          })}
        </Title>
        <Text>
          {t(
            `To view all responses, please add and confirm your own contribution first.`
          )}
        </Text>
        <Button onClick={handleClick}>{t(`Have your say`)}</Button>
      </Content>
    </Wrapper>
  );
};

export { CannotView };
