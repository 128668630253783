import * as React from 'react';
import { ListenersDivProps } from '../../types';
import { Wrapper, Listener, Name, Text } from './ListenersDiv.styles';

const ListenersDiv: React.FC<ListenersDivProps> = ({
  data,
}: ListenersDivProps) => {
  return (
    <Wrapper>
      {data.map((listener: { [key: string]: string }) => {
        return (
          <Listener key={listener.name}>
            <Name>{listener.name}</Name>
            <Text>{listener.title}</Text>
            <Text>{listener.organization}</Text>
          </Listener>
        );
      })}
    </Wrapper>
  );
};

export { ListenersDiv };
