import styled from 'styled-components';
import { MapIcon } from 'Atoms/Icons';

export const Wrapper = styled.div`
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

export const SummaryContent = styled.div`
  padding: 2.4375rem 0.875rem 0;
  max-width: 42rem;
  margin: auto;
  text-align: center;
`;

export const SummaryQuestionText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1rem;
  margin: 1rem 2.25rem;
  a {
    font-weight: 400;
  }
`;

export const StyledMapIcon = styled(MapIcon).attrs({
  width: 19,
  height: 19,
  color: '#00A85A',
})``;
