import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #000;
  margin-bottom: 2rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div``;

export const Title = styled.div<{ haveIcon: boolean }>`
  margin-left: ${({ haveIcon }) => (haveIcon ? '1.1875rem' : '')};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.4375rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
