import * as React from 'react';
import { SummaryQuestionProps } from '../../types';
import {
  Wrapper,
  TitleWrapper,
  IconWrapper,
  Title,
  TextWrapper,
} from './SummaryQuestion.styles';

const SummaryQuestion: React.FC<SummaryQuestionProps> = ({
  children,
  Icon,
  title,
}: SummaryQuestionProps) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <IconWrapper>{Icon}</IconWrapper>
        <Title haveIcon={!!Icon}>{title}</Title>
      </TitleWrapper>
      <TextWrapper>{children}</TextWrapper>
    </Wrapper>
  );
};

export { SummaryQuestion };
