import * as React from 'react';
import { HiddenCommentsPagesTemplate as Template } from 'Templates';
import { PublicAfterContribution } from './components/PublicAfterContribution/PublicAfterContribution';
import { HiddenAfterContribution } from './components/HiddenAfterContribution/HiddenAfterContribution';
import { TempHiddenPageProps } from './types';

const TempHiddenPage: React.FC<TempHiddenPageProps> = ({
  title,
  customer,
  slug,
  contributionsNumber,
  canView,
  summaryData,
  featureFlags,
}: TempHiddenPageProps) => {
  return (
    <Template title={title}>
      {featureFlags.publicCommentsAfterContribution && (
        <PublicAfterContribution
          canView={canView}
          slug={slug}
          contributionsNumber={contributionsNumber}
        />
      )}
      {featureFlags.hiddenCommentsAfterContribution && (
        <HiddenAfterContribution
          customer={customer}
          summaryData={summaryData}
        />
      )}
    </Template>
  );
};

export { TempHiddenPage };
