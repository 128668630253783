import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  margin-right: 0.4375rem;
  display: flex;
  align-items: center;
`;

export const Text = styled.div``;

export const NumbersComponent = styled.div`
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  width: fit-content;
  padding: 0.4375rem;
  margin: 1rem 0.5rem 0 0;
`;
