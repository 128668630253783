import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/react';
import { SubscribeUsersToProjectNewsProps } from '../types';

export const subscribeUsersToProjectNews = async ({
  emailValue,
}: SubscribeUsersToProjectNewsProps) => {
  try {
    const result = await fetch('/api/consent/projectNews', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userEmail: emailValue,
      }),
    });
    return result;
  } catch (error) {
    captureException(
      `error in subscribeUsersToProjectNews @ subscribeUsersToProjectNews.ts : ${error}`
    );
  }
};
