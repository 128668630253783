import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.0625rem solid #d8d8d8;
  padding: 0 1rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.875rem 0 0;
`;

export const SummaryTitle = styled.p`
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.375rem;
  margin-left: 1.4375rem;
`;

export const ImageWrapper = styled.div``;

export const HeaderText = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  margin: 0 0 1.375rem;
`;

export const Bold = styled.span`
  font-weight: 700;
`;
