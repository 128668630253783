import * as React from 'react';
import { EmailPanel } from 'Organisms';
import { subscribeUsersToProjectNews } from './services';
import { Header, Summary } from './components';
import { HiddenAfterContributionProps } from './types';

const HiddenAfterContribution: React.FC<HiddenAfterContributionProps> = ({
  customer,
  summaryData,
}: HiddenAfterContributionProps) => {
  const [email, setEmail] = React.useState<string>('');
  const [isEmailPanelOpen, setIsEmailPanelOpen] = React.useState<boolean>(true);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const onSubmit = async () => {
    await subscribeUsersToProjectNews({ emailValue: email });
    setIsSubmitted(true);
  };
  const onEmailChange = (newEmail: string) => {
    //add email change logic
    setEmail(newEmail);
  };

  const title = summaryData?.summaryQuestions?.header?.title;
  const subtitle = summaryData?.summaryQuestions?.header?.subtitle;
  const requestAccess = summaryData?.summaryQuestions?.header?.requestAccess;

  return (
    <div>
      <Header
        title={title}
        subtitle={subtitle}
        requestAccess={requestAccess}
        customerName={customer}
      />
      <Summary summaryData={summaryData} />
      {isEmailPanelOpen && (
        <EmailPanel
          emailValue={email}
          isFeedbackMessage={isSubmitted}
          onChange={onEmailChange}
          onSubmit={onSubmit}
          onClose={() => setIsEmailPanelOpen(false)}
        />
      )}
    </div>
  );
};

export { HiddenAfterContribution };
