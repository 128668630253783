import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import { SummaryHeaderProps } from '../../types';
import {
  Wrapper,
  ImageWrapper,
  TitleWrapper,
  SummaryTitle,
  // HeaderText,
  // Bold,
} from './SummaryHeader.styles';
import { SummaryHeaderImageSvg } from './SummaryHeaderImage';

const SummaryHeader: React.FC<SummaryHeaderProps> = () => {
  // const { t } = useTranslation();

  return (
    <Wrapper>
      <TitleWrapper>
        <ImageWrapper>
          <SummaryHeaderImageSvg />
        </ImageWrapper>
        <SummaryTitle>Summary</SummaryTitle>
      </TitleWrapper>
      {/* {date ? (
        <HeaderText>
          {t(`Final report will be available on:`)} <Bold>{date}</Bold>
        </HeaderText>
      ) : (
        <HeaderText>{t(`No final report date available`)}</HeaderText>
      )} */}
    </Wrapper>
  );
};

export { SummaryHeader };
