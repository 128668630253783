import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { AvatarQuestionIcon } from 'Atoms/Icons';
import { Link } from 'Components/atoms';
import { formatDate } from './utils/formatDate';
import { SummaryProps } from './types';
import {
  ListenersDiv,
  NumbersDiv,
  SummaryHeader,
  SummaryQuestion,
} from './components';
import {
  Wrapper,
  SummaryContent,
  SummaryQuestionText,
  StyledMapIcon,
} from './Summary.styles';

const Summary: React.FC<SummaryProps> = ({ summaryData }: SummaryProps) => {
  const { t } = useTranslation();
  const { projectData, summaryQuestions } = summaryData;

  const numbersDivData = [
    {
      text: `${formatDate(projectData.lauchDate)}`,
    },
    {
      text: `${projectData.commentsCount ? projectData.commentsCount : '#'}`,
    },
  ];

  return (
    <Wrapper data-testid="Summary-Wrapper">
      <SummaryHeader
        date={
          projectData.endDate === 'undefined'
            ? undefined
            : formatDate(projectData.endDate)
        }
      />
      <SummaryContent>
        {projectData.ONSareaName ? (
          <SummaryQuestion
            title={t(projectData.ONSareaName)}
            Icon={<StyledMapIcon />}
          >
            <NumbersDiv data={numbersDivData} key={'numbers1'} />
          </SummaryQuestion>
        ) : (
          <SummaryQuestionText>
            <NumbersDiv data={numbersDivData} key={'numbers1'} />
          </SummaryQuestionText>
        )}

        <SummaryQuestion title={t(`Why are we engaging with you?`)}>
          <SummaryQuestionText key={'question1'}>
            {summaryQuestions?.answers[0] || t('Freestyle')}
          </SummaryQuestionText>
        </SummaryQuestion>

        <SummaryQuestion title={t(`Why you should participate`)}>
          <SummaryQuestionText key={'question2'}>
            {summaryQuestions?.answers[1] || t('Freestyle')}
          </SummaryQuestionText>
        </SummaryQuestion>

        <SummaryQuestion
          title={t(`Who is listening?`)}
          Icon={<AvatarQuestionIcon />}
        >
          <ListenersDiv
            data={summaryQuestions?.people || []}
            key={'listeners1'}
          />
        </SummaryQuestion>

        <SummaryQuestion title={t(`How to keep updated?`)}>
          <Trans>
            <SummaryQuestionText key={'question3'}>
              {'Subscribe to our '}
              <Link to={'/news'}>{'project news'}</Link>
              {" to stay in the know. We've also published a "}
              <Link to={'/timeline'}>{'timeline'}</Link>
              {' of what you can expect when. '}
            </SummaryQuestionText>
          </Trans>
        </SummaryQuestion>
      </SummaryContent>
    </Wrapper>
  );
};

export { Summary };
