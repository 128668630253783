import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SendIcon, MessageCircleIcon } from 'Atoms/Icons';
import { NumbersDivProps } from '../../types';
import {
  Wrapper,
  NumbersComponent,
  IconWrapper,
  Text,
} from './NumbersDiv.styles';

const NumbersDiv: React.FC<NumbersDivProps> = ({ data }: NumbersDivProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <NumbersComponent>
        <IconWrapper>
          <SendIcon />
        </IconWrapper>
        <Text>
          {t('Launch:')} <b>{data[0].text}</b>
        </Text>
      </NumbersComponent>
      <NumbersComponent>
        <IconWrapper>
          <MessageCircleIcon />
        </IconWrapper>
        <Text>
          <b>{data[1].text}</b> {t('Comments')}
        </Text>
      </NumbersComponent>
    </Wrapper>
  );
};

export { NumbersDiv };
